export const brands = {
  pukkawin: {
    footerLogo: "/logo-pukkawin.png",
    bannerImage: "/assets/images/banners/casino-pukkawin.png",
    bannerImageMbl: "/assets/images/banners/casino-pukkawin.png",
    sportBannerImg: ["/assets/images/banners/sport.jpg"],
    sportBannerMblImg: ["/assets/images/banners/sport-mbl.png"],
    casinoBannerImg: [
      "/assets/images/banners/casino2.png",
      "/assets/images/banners/first-deposit.png",
      "/assets/images/banners/second-deposit.png",
      "/assets/images/banners/free-spins.png",
    ],
    casinoBannerMblImg: [
      "/assets/images/banners/casino2.png",
      "/assets/images/banners/first-deposit.png",
      "/assets/images/banners/second-deposit.png",
      "/assets/images/banners/free-spins.png",
    ],
    virtualBannerImg: ["/assets/images/banners/virtual.jpg"],
    virtualBannerMblImg: ["/assets/images/banners/virtual-mbl.jpg"],
    logo: "/logo-pukkawin.png",
    title: "Pukkawins",
    name: "pukkawin",
    address: "Kaya W.F.G. (Jombi) Mensing 24 Unit A, Willemstad, Curacao",
  },
  betkudos: {
    footerLogo: "/Logo.svg",
    bannerImage: "/assets/images/banners/casino.jpg",
    bannerImageMbl: "/assets/images/banners/casino-mbl.jpg",
    sportBannerImg: ["/assets/images/banners/sport.jpg"],
    sportBannerMblImg: ["/assets/images/banners/sport-mbl.png"],
    casinoBannerImg: [
      "/assets/images/banners/casino2.png",
      "/assets/images/banners/sports.png",
      "/assets/images/banners/first-deposit.png",
      "/assets/images/banners/second-deposit.png",
      "/assets/images/banners/free-spins.png",
      "/assets/images/banners/bet&get.png",
      "/assets/images/banners/telegram.png",
    ],
    casinoBannerMblImg: [
      "/assets/images/banners/casino2.png",
      "/assets/images/banners/sports.png",
      "/assets/images/banners/first-deposit.png",
      "/assets/images/banners/second-deposit.png",
      "/assets/images/banners/free-spins.png",
      "/assets/images/banners/bet&get.png",
      "/assets/images/banners/telegram.png",
    ],
    virtualBannerImg: ["/assets/images/banners/virtual.jpg"],
    virtualBannerMblImg: ["/assets/images/banners/virtual-mbl.jpg"],
    logo: "/Logo.svg",
    title: "BetKudos",
    name: "betkudos",
    address: "Kaya W.F.G. (Jombi) Mensing 24 Unit A, Willemstad, Curacao",
    licenseToken:
      "ZXlKcGRpSTZJbTVGYzBRMk16RklVbU5qYXk4MUszaGxhVTAwUmtFOVBTSXNJblpoYkhWbElqb2lPVmh0WlRaUUwxWXJkR042VWl0UFFtNHlWbkpOV1ZjeWJrZHljMHRLU0VkMmFEWmxkVlZuYW5wbVdUMGlMQ0p0WVdNaU9pSmpPV1l3WWprM05qZzFOemN6TkdWaE1tSXhZMlpqT0RaaFl6a3lZelU1WXpnd00yUTFZek15Tm1Oa1lXSTNOak0zTnpZME1UWTJaamRpTURWaU1XWmpJaXdpZEdGbklqb2lJbjA9",
  },
  sojibet: {
    footerLogo: "/sojabet.png",
    bannerImage: "/assets/images/banners/weekly-free.jpeg",
    bannerImageMbl: "/assets/images/banners/casino-mbl.jpg",
    sportBannerImg: ["/assets/images/banners/bet-get.jpeg"],
    sportBannerMblImg: ["/assets/images/banners/bet-get.jpeg"],
    casinoBannerImg: ["/assets/images/banners/weekly-free.jpeg"],
    casinoBannerMblImg: ["/assets/images/banners/weekly-free.jpeg"],
    virtualBannerImg: ["/assets/images/banners/weekly-free.jpeg"],
    virtualBannerMblImg: ["/assets/images/banners/weekly-free.jpeg"],
    logo: "/sojabet.png",
    title: "SojiBet",
    name: "sojibet",
    address: "Kaya W.F.G. (Jombi) Mensing 24 Unit A, Willemstad, Curacao",
    licenseToken: "ZXlKcGRpSTZJblZGTUZGd0wxVm1RbmRqSzFOVlVGZEliRkZZTlVFOVBTSXNJblpoYkhWbElqb2laa1ZGZUc5UFVUTnRjekozWlZkbWQyUlpkRk50VVQwOUlpd2liV0ZqSWpvaU5qYzROV015WlRNelpqZ3dZakE1TXpVeU5UUmhOVFZoTWprMk5UaGtaREE1TlRSak56TmlOVFZoT1RjeFpURXhaRGd6WWpJNE5EbGxaVFF6TVRRd1pTSXNJblJoWnlJNklpSjk=",
  },
};
